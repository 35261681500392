import image1 from "../../../../assets/images/QuienesSomos/compromiso.jpg";
import { Contactanos } from "../componentesDeInicio/componenteContactanos/Contactanos";
import { QuienesSomosCont } from "./componenteContactanos/QuienesSomosCont";
import { Mision } from "./componenteMision/Mision";
import { Vision } from "./componenteVision/Vision";
import { HeaderSomos } from "./HeaderQuinesSomos/HeaderSomos";

import { QuienesSomos } from "./QuienesSomos/QuienesSomos";

export const QuienesS = () => {
  return (
    <div id="quienesSTop">
      <HeaderSomos />
      <QuienesSomos />
      <Mision/>
      <Vision/>
      <QuienesSomosCont/>
    </div>
  );
};
