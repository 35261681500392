import { HeaderSomos } from "../HeaderQuinesSomos/HeaderSomos";
import image1 from '../../../../assets/images/QuienesSomos/mision.jpg'
import './Mision.css'

export const Mision = () => {
  return (
    <div>
      <div className="mision-container">
        <div className="cardM">
          <img src={image1} alt="Image1" />
          
        </div>
        <div className="cardMisi" style={{backgroundColor: "#A4A4A4"}}>
          <div className="cardM-content">
            <h2>Misión </h2>
            <br></br>
            <p>SCOM SAS, es una empresa creada para suplir tareas diferentes y complementarias de la actividad principal de nuestros clientes, en el área de suministros, construcciones y mantenimiento; realizando cada una de las actividades con los más altos estándares de calidad, enmarcadas siempre en las leyes, cuidando el medio ambiente y contribuyendo al bienestar social con la generación de empleo.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
