import "./imagenP.css";

export const ImagenP = () => {
  return (
    <div>
      <div>
        <header className="mastheadI">
          <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
            <div className="align-content-end align ra">
              <div className="justifyr">
                <p className="parraExa">
                  <span className="spanar">
                    <strong>16</strong>
                  </span>
                  <br />
                </p>
                <div className="justify">
                  <p className="parraExa">Años</p>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};
