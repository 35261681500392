// Inicio.js
import React from "react";
import "./Inicio.css";

export const Inicio = () => {
  return (
    <div>
      <header className="masthead">
        <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
          <div className="masthead-content">
            <div className="text-left">
              <p className="parra">
                En&nbsp;
                <span className="spana">
                  <strong>SCOM</strong>
                </span>
                : Edificamos
              </p>
              <p className="parra">
                <strong>excelencia</strong>
              </p>

              <div className="containera">
                <p className="parras">
                  Fusionamos innovación y solidez para ofrecer proyectos de
                  construcción, <br /> suministros y mantenimiento que superan
                  expectativas.
                </p>
              </div>

              <div>
                <a
                  className="btn btn-danger  justify-content-center   align-items-center "
                  href="#contacto"
                >
                  <i className="fa-brands fa-whatsapp mr-2"  style={{ fontSize: "24px" }}></i>
                  <p className="conta m-0"> &nbsp;Contáctanos</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
