// Inicio.js
import React from "react";
import "./InicioQS.css";

export const InicioQS = () => {
  return (
    <div>
      <header className="mastheadQS">
        <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
          <div className="masthead-content">
            <div className="text-left">
             
             <h2 className="h2QS">¿Quienes somos?</h2>
                <br></br>
              <div className="containeraQS">
                <p className="parrafoQS">
                En SCOM SAS, no solo nos definimos como una empresa, somos un equipo comprometido con la excelencia y la innovación en el sector de suministros, construcciones y mantenimiento. Con una visión clara y una sólida trayectoria, nos hemos consolidado como aliados estratégicos para el desarrollo de proyectos que impactan positivamente en nuestra comunidad.
                </p>
              </div>

            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
