import { Contactanos } from "../../componentesDeInicio/componenteContactanos/Contactanos";


export const ContactanosServicio = () => {
    return (

        <div>
            <Contactanos/>
        </div>
    );
}