import React, { useState } from 'react';
import './Carrusel.css';

interface CarouselItem {
  image: string;
  title: string;
  description: string;
}

const carouselItems: CarouselItem[] = [
  {
    image: require("../../../../assets/images/fotoServicios/suministros.jpg"),
    title: 'Suministros',
    description: 'La empresa proporciona servicios de suministro de personal especializado y equipos para diversas necesidades operativas, incluyendo profesionales en salud y construcción, limpieza, eventos, climatización, maquinaria de construcción, mobiliario de oficina, tecnología y suministros de oficina.',
  },

  {
    image: require("../../../../assets/images/fotoServicios/topo.jpg"),
    title: 'Realización de estudios topograficos',
    description: 'Estudios de suelos, residencias de obra, mejoras locativas, presupuestos de obra, remodelaciones arquitectónicas y levantamientos arquitectónicos.',
  },

  {
    image: require("../../../../assets/images/fotoServicios/arquitectura.png"),
    title: 'Servicios Arquitectonicos',
    description: 'Ofrecemos servicios integrales para el sector de la construcción, desde trámites de licencia hasta asesoramiento inmobiliario y diseño de estructuras según las normativas vigentes, incluyendo reconocimientos arquitectónicos y gestión de propiedad horizontal. También proporcionamos servicios de renderización, diseño de rutas de evacuación y redes para tendidos de fibra óptica y otros desechos.',
  },

  {
    image: require("../../../../assets/images/fotoServicios/obra.jpg"),
    title: 'Acompañamiento en procesos de obra',
    description: 'Ofrecemos servicios completos de construcción, desde obra gris hasta acabados finos, incluyendo plomería, pisos laminados, drywall y mantenimiento eléctrico. '
  },

  {
    image: require("../../../../assets/images/fotoServicios/obra.jpg"),
    title: 'Acompañamiento en procesos de obra',
    description: 'Ofrecemos servicios completos de construcción, desde obra gris hasta acabados finos, incluyendo plomería, pisos laminados, drywall y mantenimiento eléctrico. '
  },
  // Agrega más elementos aquí si es necesario

  {
    image: require("../../../../assets/images/fotoServicios/obra.jpg"),
    title: 'Acompañamiento en procesos de obra',
    description: 'Ofrecemos servicios completos de construcción, desde obra gris hasta acabados finos, incluyendo plomería, pisos laminados, drywall y mantenimiento eléctrico. '
  },

  {
    image: require("../../../../assets/images/fotoServicios/obra.jpg"),
    title: 'Acompañamiento en procesos de obra',
    description: 'Ofrecemos servicios completos de construcción, desde obra gris hasta acabados finos, incluyendo plomería, pisos laminados, drywall y mantenimiento eléctrico. '
  },

  {
    image: require("../../../../assets/images/fotoServicios/obra.jpg"),
    title: 'Acompañamiento en procesos de obra',
    description: 'Ofrecemos servicios completos de construcción, desde obra gris hasta acabados finos, incluyendo plomería, pisos laminados, drywall y mantenimiento eléctrico. '
  },
 


];

const Carousel: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? carouselItems.length - 1 : prevSlide - 1));
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === carouselItems.length - 1 ? 0 : prevSlide + 1));
  };

  return (
    <section className="carrusel">
       <div className='titulocarrusel'>
      <h1>
       Servicios que Ofrecemos
      </h1>
      <br />
      </div>
      <div className="carousel">
      <div className="carousel-inner">
        <img src={carouselItems[currentSlide].image} alt={carouselItems[currentSlide].title} />
        <div className="carousel-content">
          <h2>{carouselItems[currentSlide].title}</h2>
          <p>{carouselItems[currentSlide].description}</p>
          <button className="learn-more">Contactanos</button>
        </div>
      </div>
      <button className="carousel-control-prev" onClick={handlePrevSlide}>
        <span className="carousel-control-prev-icon"></span>
      </button>
      <button className="carousel-control-next" onClick={handleNextSlide}>
        <span className="carousel-control-next-icon"></span>
      </button>
    </div>
    </section>
    
  );
};

export default Carousel;