import React, { useState } from "react";
import "./Portafolio.css";
import foto01 from '../../../../assets/images/fotosPortafolio/scom01.jpeg'
import foto02 from '../../../../assets/images/fotosPortafolio/scom02.jpeg'
import foto03 from '../../../../assets/images/fotosPortafolio/scom03.jpeg'
import foto04 from '../../../../assets/images/fotosPortafolio/scom04.jpeg'
import foto05 from '../../../../assets/images/fotosPortafolio/scom05.jpeg'
import foto06 from '../../../../assets/images/fotosPortafolio/scom06.jpeg'

interface Image {
  id: number;
  src: string;
  title: string;
}

const Portfolio: React.FC = () => {
  // Definir el estado para las imágenes
  const [images] = useState<Image[]>([
    {
      id: 1,
      src: foto01,
      title: "Image 1",
    },
    {
      id: 2,
      src: foto02,
      title: "Image 2",
    },
    {
      id: 3,
      src: foto03,
      title: "Image 3",
    },
    {
      id: 4,
      src: foto04,
      title: "Image 4",
    },
    {
      id: 5,
      src: foto05,
      title: "Image 5",
    },
    {
      id: 6,
      src: foto06,
      title: "Image 6",
    },
  ]);

  return (
    <div>
        <div>
        <div style={{height: "70px", width: "100%"}}></div>

        <p className="justify-content-center parrafoPor">
                Portafolio&nbsp;
                <span className="spana">
                  <strong>de proyectos</strong>
                </span>
              </p>
              <p className="parra">
                <strong>excelencia</strong>
              </p>
        </div>
        <div className="portfolio">
          {images.map((image) => (
            <div className="image-container" key={image.id}>
              <img
                src={image.src}
                alt={image.title}
                className="portfolio-image"
              />
            </div>
          ))}
        </div>
    </div>
  );
};

export default Portfolio;
