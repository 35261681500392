import { HeaderSomos } from "../HeaderQuinesSomos/HeaderSomos";
import image1 from '../../../../assets/images/QuienesSomos/compromiso.jpg'
import './QuienesSomos.css'

export const QuienesSomos = () => {
  return (
    <div>
      <div className="quienes-somos-container">
        <div className="cardQS">
          <img src={image1} alt="Image1" />
          
        </div>
        <div className="cardQSa" style={{backgroundColor: "#A4A4A4"}}>
          <div className="card-contentQS">
            <h2>Quienes somos </h2>
            <br></br>
            <p>En Scom, somos más que una empresa; somos un equipo dedicado a edificar sueños y fortalecer cimientos. Con una trayectoria sólida en el mercado de suministros de construcción, nos hemos posicionado como aliados estratégicos en el desarrollo de proyectos que moldean el horizonte de nuestra comunidad.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
