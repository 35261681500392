import React from 'react';
import SCOM5 from "../../../../assets/images/fotoCards/SCOM-05.png";
import SCOM11 from "../../../../assets/images/fotoCards/SCOM-11.png";
import SCOM22 from "../../../../assets/images/fotoCards/SCOM-22.png";
import "./Card.css"
import { Link } from 'react-router-dom';

// Define los tipos de las props de Card, incluyendo la propiedad para la imagen
interface CardProps {
  title: string;
  description: string;
  src?: string; // Propiedad opcional para la URL de la imagen
}

// Componente de la tarjeta en TypeScript
const Card: React.FC<CardProps> = ({ title, description, src }) => {
  return (
    <div className="card">
      {src && (
        <img src={src} alt={title} className="card-image" />
      )}
      <h3 className="card-title">{title}</h3>
      <p className="card-description">{description}</p>
      <Link to= "/Servicio#serviciosTop"  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
      <a
        href="#serviciosTop" 
        className="card-button"
        
      >
        Ver más
      </a>
      </Link>
    </div>
  );
};

// Componente contenedor de las tarjetas
const CardContainer: React.FC = () => {
  return (
    <section>
      <div className='titulocard'>
      <h1>
        <span className="color-part1">Construyendo </span>
        <span className="color-part3">sueños,<br/></span>
        <span className="color-part2"> superando expectativas.</span>
      </h1>
      </div>
       <div className="card-container">
      <Card
        title="Innovación en Diseño"
        description="Damos forma a los sueños de nuestros clientes con creatividad y precisión vanguardista."
        src={SCOM5}
      />
      <Card
        title="Calidad sin Compromisos"
        description="La calidad es la base de cada construcción, con materiales de primera y atención al detalle."
        src={SCOM11}
      />
      <Card
        title="Servicio Integral"
        description="Aseguramos que cada fase del proyecto se ejecute a la perfección, desde el suministro hasta el mantenimiento."
        src={SCOM22}
      />
    </div>
    </section>
   
  );
};

export default CardContainer;
