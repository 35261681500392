import React, { useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./header.css";
import logo from "../../../../assets/images/logo/logo.png";
import { Link, useNavigate } from "react-router-dom";

export const Header = () => {
  const navbarShrink = () => {
    const navbarCollapsible = document.body.querySelector("#mainNav");
    if (!navbarCollapsible) {
      return;
    }
    if (window.scrollY === 0) {
      navbarCollapsible.classList.remove("navbar-shrink");
    } else {
      navbarCollapsible.classList.add("navbar-shrink");
    }
  };

  useEffect(() => {
    // Shrink the navbar
    navbarShrink();

    // Shrink the navbar when page is scrolled
    document.addEventListener("scroll", navbarShrink);

    // Collapse responsive navbar when toggler is visible
    const navbarToggler = document.body.querySelector(".navbar-toggler");
    const responsiveNavItems = Array.from(
      document.querySelectorAll("#navbarResponsive .nav-link")
    ) as HTMLElement[];
    responsiveNavItems.forEach((responsiveNavItem) => {
      responsiveNavItem.addEventListener("click", () => {
        if (
          window.getComputedStyle(navbarToggler as Element).display !== "none"
        ) {
          (navbarToggler as HTMLElement).click();
          const navbarCollapsible = document.body.querySelector("#mainNav");
          navbarCollapsible?.classList.remove("navbar-shrink"); // Remove navbar-shrink class on collapse
        }
      });
    });
  }, []);

  const handleLogoClick = () => {
    // Añadir un retraso de 100 milisegundos antes de desplazar la página
    setTimeout(() => {
      const inicioTopElement = document.getElementById("inicioTop");
      if (inicioTopElement) {
        inicioTopElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <div>
      <Navbar
        expand="lg"
        className="navbar navbar-expand-lg navbar-light fixed-top navbar-shrink"
        id="mainNav"
      >
        <div id= "inicioTop"></div>
        <Container>  
          <Navbar.Brand onClick={handleLogoClick}>
            <a href="#inicioTop"  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} >
            <img src={logo} alt="logo.png" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link  >
              <Link className="nav-link"style={{ textDecoration: "none"}} to="/Inicio" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} >Inicio</Link>
                </Nav.Link>
              <Nav.Link>
                <Link className="nav-link" style={{ textDecoration: "none"}} to="/Servicio#serviciosTop"  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} >Servicios</Link>
              </Nav.Link>
              <Nav.Link>
                <Link className="nav-link" style={{ textDecoration: "none"}} to="/QuienesSomos#quienesSTop"  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} >Quienes somos</Link>
              </Nav.Link>
              <Nav.Link className="nav-link" style={{ textDecoration: "none"}} href="#contacto">Contáctanos</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
