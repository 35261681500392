import { Routes, Route } from "react-router-dom";
import { lazy } from "react";
import { InicioF } from "../components/componentesDeInicio/Inicio";
import { QuienesS } from "../components/componenteQuienesSomos/QuienesS";
import { Servicios } from "../components/componentesDeServicios/servicios";


const LazyHeaderInicio = lazy(() => import("../components/componentesDeInicio/Inicio").then(()=>({default: InicioF})));
const LazyHeaderServicio = lazy(() => import("../components/componentesDeServicios/servicios").then(()=>({default: Servicios    })));
const LazyHeaderSomos= lazy(() => import("../components/componenteQuienesSomos/QuienesS").then(()=>({default: QuienesS})));
export const Routeo = () =>{
    return(
        <Routes>
           <Route path="/*" element={<LazyHeaderInicio/>}/>
           <Route path="/Inicio" element={<LazyHeaderInicio/>}/>
           <Route path="/Servicio" element={<LazyHeaderServicio/>}/>
           <Route path="/QuienesSomos" element={<LazyHeaderSomos/>}/>
        </Routes>
    );
}