import { Header } from "../../componentesDeInicio/componenteHeader/Header";
import { Inicio } from "../../componentesDeInicio/componenteInicio/Inicio";
import { InicioF } from "../../componentesDeInicio/Inicio";
import './Servicio.css'

export const HeaderServicio = () => {
  return (
    <div>
      <Header />
      <header className="mastheadSer">
        <div   className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
          <div className="masthead-content">
            <div className="text-left">
              <p className="parra">
              Servicios 
              </p>

              <div className="containera">
                <p className="parras">
                Construyendo el futuro con precisión y calidad. Desde suministros de construcción hasta la ejecución de obras civiles, pasando por estudios topográficos y diseño arquitectónico, acompañamos cada proyecto con excelencia y experiencia.
                </p>
              </div>

              <div>
               
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
