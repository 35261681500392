import { ImagenP } from './imagenP';
import './nuestraExp.css'
import { Texto } from './texto';
export const NuestraExperiencia =()=>{
    return(
      <div style={{marginTop: "70px"}}>
        <div className="d-flex justify-content-center mt-xl-5">
             <p className="parraEx">
                Nuestra &nbsp;
                <span className="spana">
                  <strong>Experiencia</strong>
                </span>         
              </p>
        </div>
        <ImagenP/>
        <Texto/>
        </div>
    );
}