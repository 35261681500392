import { HeaderSomos } from "../HeaderQuinesSomos/HeaderSomos";
import image1 from "../../../../assets/images/QuienesSomos/vision.jpg";
import "./Vision.css";

export const Vision = () => {
  return (
    <div>
      <div className="vision-container">
        <div className="cardVisi" style={{ backgroundColor: "#A4A4A4" }}>
          <div className="right">
            <div className="cardV-content">
              <h2>Visión </h2>
              <br></br>
              <p>
                SCOM SAS., para el 2030 será reconocida como una de las empresas
                lideres a nivel nacional apoyada en la experiencia adquirida,
                trabajos realizados y alianzas estratégicas sólidas; buscando
                siempre la eficiencia y mejoramiento continuo en cada una de sus
                operaciones.
              </p>
            </div>
          </div>
        </div>
        <div className="cardV">
          <img src={image1} alt="Image1" />
        </div>
      </div>
    </div>
  );
};
