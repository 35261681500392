import React from "react";
import "./Contactanos.css"; // Asegúrate de tener estilos CSS correspondientes
import logoLunity from '../../../../assets/images/logo/lunity.png'
import { Link } from "react-router-dom";

export const Contactanos = () => {
  return (
    <div>
      <div id="contacto" style={{ height: "50px", width: "100%" }}></div>
      <h2 style={{ color: "#C60202" }}>Contáctanos</h2>
      <div style={{ height: "50px", width: "100%" }}></div>
      <div className="contact-container">
        <div className="contact-info">
          <div className="contact-item">
            <i className="fa-solid fa-phone"></i>
            <p>Telefono</p>
            <p>+57 3175754484 +57 310 4233774</p>
          </div>
          <div className="contact-item">
            <i className="fas fa-map-marker-alt"></i>
            <p>Ubicación</p>
            <p>Carrera 2 No. 42-09 Tunja</p>
          </div>
          <div className="contact-item">
            <i className="far fa-envelope"></i>
            <p>Correo</p>
            <p style={{ marginLeft: "-10px" }}>
              <u>gerencia@scomsas.com</u>
            </p>
          </div>
          <div className="contact-item">
            <i className="far fa-clock"></i>
            <p>Horaio</p>
            <p>Lunes a viernes:</p>
            <p>8 a.m. - 5 p.m.</p>
          </div>
        </div>
        <div className="d-flex logo">
          <img src={logoLunity} alt="logo.png"/>
          <p>Desarrollado por: <Link to="http://lunityweb.com/" target="_blank" rel="noopener noreferrer" style={{color: "white"}}>Lunity Web</Link></p>
        </div>
      </div>
    </div>
  );
};
