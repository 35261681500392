import CardContainer from "./componenteCards/Card"
import { Contactanos } from "./componenteContactanos/Contactanos"
import { NuestraExperiencia } from "./componenteExperiencia/nuestraExperiencia"
import { Header } from "./componenteHeader/Header"
import { Inicio } from "./componenteInicio/Inicio"
import Portfolio from "./componentePortafolio/Portafolio"
import WhatsApp from "./componenteWhatsApp/WhatsApp"


export const InicioF=()=>{
    return(
        <div>
            <Header/>
            <Inicio />
            <CardContainer />
            <NuestraExperiencia />
            <Portfolio />
            <Contactanos />
            <WhatsApp phoneNumber="+573175754484"/>
        </div>
    )
}