import React, { Suspense, useEffect } from "react";
import "./App.css";
import { Header } from "./app/components/componentesDeInicio/componenteHeader/Header";
import { Inicio } from "./app/components/componentesDeInicio/componenteInicio/Inicio";
import { NuestraExperiencia } from "./app/components/componentesDeInicio/componenteExperiencia/nuestraExperiencia";
import CardContainer from "./app/components/componentesDeInicio/componenteCards/Card";
import Portfolio from "./app/components/componentesDeInicio/componentePortafolio/Portafolio";
import { Contactanos } from "./app/components/componentesDeInicio/componenteContactanos/Contactanos";
import { BrowserRouter } from "react-router-dom";
import { Routeo } from "./app/routes/Routeo";
import { InicioF } from "./app/components/componentesDeInicio/Inicio";

function App() {
  const charge = (
    <div className="d-flex justify-content-center mt-5 ">
      <button className="btn btn-primary" disabled>
        <span
          className="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Cargando...
      </button>
    </div>
  );

  return (
    <div className="App">
      <body id="page-top">
        <BrowserRouter>
          <Suspense fallback={charge}>
            <Routeo />
          </Suspense>
        </BrowserRouter>
      </body>
    </div>
  );
}

export default App;
