import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Modal from 'react-modal';
import './WhatsApp.css'; // Archivo de estilos CSS

interface WhatsAppButtonProps {
  phoneNumber: string; // Número de teléfono al que se enviará el mensaje
}

const WhatsApp: React.FC<WhatsAppButtonProps> = ({ phoneNumber }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [message, setMessage] = useState('');

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSendMessage = () => {
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
    closeModal();
  };

  return (
    <div className="whatsapp-button-container">
      <button className="whatsapp-button" onClick={openModal}>
        <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Enviar mensaje por WhatsApp"
        className="whatsapp-modal"
      >
        <h2>Enviar mensaje por WhatsApp</h2>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Escribe tu mensaje aquí..."
        />
        <button onClick={handleSendMessage}>Enviar mensaje</button>
      </Modal>
    </div>
  );
};

export default WhatsApp;
