import WhatsApp from "../componentesDeInicio/componenteWhatsApp/WhatsApp"
import Carrusel from "./cardServicio/Carrusel"
import Pdf from "./componenteBrochure/Pdf"


import { ContactanosServicio } from "./componenteContactanos/ContactanosServicio"

import { HeaderServicio } from "./componentesInicio/headerServicio"


export const Servicios=()=>{
    return(
        <div id="serviciosTop">
        <HeaderServicio />
        <Carrusel />
        <Pdf pdfUrl='/pdf/brochure.pdf' buttonText="Descargar Brochure" />
        <ContactanosServicio />
        <WhatsApp phoneNumber="+573175754484" />
      </div>
    )
}