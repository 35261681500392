import "./texto.css";

export const Texto = () => {
  return (
    <div className="container">
      <div className="texto-container">
        <p className="parraExaf">
          Con 16 años de experiencia, SCOM
          es sinónimo de excelencia en 
          construcción, suministros 
          y mantenimiento. Nuestro legado se
          construye sobre proyectos 
          innovadores y la plena satisfacción
          de nuestros clientes. Elegir SCOM 
          es optar por un socio confiable y
          experto.
        </p>
      </div>
    </div>
  );
};
