import React from 'react';
import './Pdf.css'; // Archivo de estilos CSS

const Pdf: React.FC<{ pdfUrl: string; buttonText?: string }> = ({ pdfUrl, buttonText = 'Descargar Brochure' }) => {
  return (
    <div className="download-button-container">
    <h1>Descarga el brochure</h1>
      <a href={pdfUrl} download className="download-button">
        {buttonText}
      </a>
    </div>
  );
};

export default Pdf;
